<template>
  <v-card>
    <v-card-title>
      Dar por pagada <span v-if="$root.acceso('DEV')" >&nbsp;[{{ idLiquidacion }}]</span>
    </v-card-title>
    <v-divider></v-divider>
    <v-card-text class="mt-4">
      <v-textarea label="Comentario pago (opcional)" filled no-resize auto-grow v-model="comentario"></v-textarea>
      <v-card-subtitle>
        Se pagarán {{ parseFloat(liq.acumulativo).toFixed(2) }} € en compensación de la deuda acumulada
      </v-card-subtitle>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn @click.stop="$emit('input', false)" color="error" text>Cancelar</v-btn>
      <v-btn @click.stop="pagar" color="primary" text>Aceptar</v-btn>
    </v-card-actions>
  </v-card>  
</template>

<script>
import {req, isNumber} from '@/utils/validations.js'

export default {
  props: {
    idLiquidacion: String,
    liq: Object
  },
  data() {
    return {
      comentario: '',

      rules: {req, isNumber}
    }
  },
  methods: {
    async pagar() {
      axios({
        method: 'POST',
        url: `${process.env.VUE_APP_OUR_API_URL}/liquidaciones/pagar.php`,
        data: {
          token: this.$store.getters.getJwtEmpresa,
          idLiquidacion: this.idLiquidacion,
          comentarioPago: this.comentario,
        }
      }).then((res) => {
        this.$emit('snack', 'Se ha pagado la liquidación correctamente');
        this.$emit('reload');
        this.$emit('input', false);
      }).catch((err) => {
        console.error(err);
        this.$emit('snack', 'No se ha podido pagar la liquidación');
        this.$emit('reload');
        this.$emit('input', false);
      })
    }
  }
}
</script>

<style>

</style>